








































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { startOfDay } from 'date-fns';
import sink from '@/sink';
import {
  dateFormatRule,
  dateRangeValidRule,
  dateValidRule,
  extractPartsFromDate,
  formatISODate,
  isDateFormatValid,
  isDateValid,
} from '../i18n-date';

export default defineComponent({
  emits: ['change'],
  props: {
    isoDate: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    minISODate: {
      type: String,
      required: false,
    },
    maxISODate: {
      type: String,
      required: false,
    },
    additionalRules: {
      type: Array,
      required: false,
      default: () => [],
    },
    closeOverride: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, ctx) {
    const i18nDateSetting = sink.select(
      'capabilities.international.datetimeFormat',
    ).value;

    const calendarOpen = ref(false);
    const textField = ref(null);
    const readableDate = ref(formatISODate(props.isoDate, i18nDateSetting));

    watch(
      () => props.isoDate,
      newVal => {
        readableDate.value = formatISODate(newVal, i18nDateSetting);
      },
    );

    const requiredRule = dateString =>
      !!dateString.length || 'Date is required';

    const dateRangeRule = dateString => {
      return dateRangeValidRule({
        dateISOString:
          dateString.length >= 10 &&
          extractPartsFromDate(dateString, i18nDateSetting).join('-'),
        minDateISOString: props.minISODate,
        maxDateISOString: props.maxISODate,
        i18nDateSetting: i18nDateSetting,
      });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let rulesArray: any[] = [
      dateString => dateFormatRule(dateString, i18nDateSetting),
      dateString => dateValidRule(dateString, i18nDateSetting),
    ];

    const rules = computed(() => {
      if (props.required) {
        rulesArray.unshift(requiredRule);
      }

      if (props.minISODate && props.maxISODate) {
        rulesArray.push(dateRangeRule);
      }

      if (props.additionalRules.length) {
        rulesArray = rulesArray.concat(props.additionalRules);
      }

      return rulesArray;
    });

    const datePickerReadableDate = computed(() => {
      return extractPartsFromDate(readableDate.value, i18nDateSetting)?.join('-');
    });

    const onCalendarClick = () => {
      textField.value.focus();
      calendarOpen.value = true;
    };

    const emit = val => {
      ctx.emit('change', val);
    };

    const onDateChange = (newDate: string) => {
      const [year, month, day] = newDate
        .split('-')
        .map(num => parseInt(num, 10));

      const localMidnight = new Date(year, month - 1, day);
      emit(localMidnight.toISOString());
    };

    const onTextChange = newDateString => {
      if (!newDateString.length) {
        return emit(newDateString);
      }

      if (
        isDateFormatValid(newDateString, i18nDateSetting) &&
        isDateValid(newDateString, i18nDateSetting)
      ) {
        const [year, month, day] = extractPartsFromDate(
          newDateString,
          i18nDateSetting,
        );

        return onDateChange(`${year}-${month}-${day}`);
      }

      return;
    };

    const onClearClick = () => emit('');

    const onTodayClick = () => {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const now = new Date();
      const nowInUserTimeZone = new Date(
        now.toLocaleString('en-US', { timeZone: timeZone }),
      );
      const startOfTodayInUserTimeZone = startOfDay(nowInUserTimeZone);
      const timezoneOffset = nowInUserTimeZone.getTimezoneOffset() * 60000;
      const adjustedStartOfTodayInUserTimeZone = new Date(
        startOfTodayInUserTimeZone.getTime() - timezoneOffset,
      );
      const isoStartOfTodayInUserTimeZone = adjustedStartOfTodayInUserTimeZone.toISOString();
      onDateChange(isoStartOfTodayInUserTimeZone);
    };

    return {
      calendarOpen,
      textField,
      readableDate,
      rules,
      onCalendarClick,
      onDateChange,
      onTextChange,
      onClearClick,
      onTodayClick,
      datePickerReadableDate
    };
  },
});
